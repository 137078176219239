@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;800&display=swap");
@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
@import "node_modules/@ngneat/hot-toast/src/styles/styles.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  color-scheme: only light;
}


.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h {
  white-space: normal !important;
}

//or just

.sc-ion-label-md-h {
  white-space: normal !important;
}

ion-modal.component-modal {
  .modal-wrapper {
    height: 40%;
    width: 50%;
    border-radius: 15px;
  }
  width: auto;
}

.backdrop-no-scroll ion-content {
  --overflow: hidden;
}
.backdrop-no-scroll ion-backdrop + * ion-content {
  --overflow: visible;
}

.my-modal-class-2023 {
  --border-radius: 4px;
  --width: 80%;
  --height: 90%;
}

body {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  position: static !important;
  overflow: auto !important;
  height: auto !important;
}

.courses_sm_card {
  background-image: url(./assets/img/card-sm-bg.png);
  background-repeat: no-repeat;
  background-position: right center;
}

.courses_card {
  background-image: url(./assets/img/Science&Tech.png);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 124px 124px;
}

.courses_card_globe {
  background-image: url(./assets/img/globe.png);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 124px 124px;
}

.courses_card_script {
  background-image: url(./assets/img/script.png);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 124px 124px;
  
}

.more_users img {
  display: inline-block;
  margin-left: -10px;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  object-fit: cover;
}

.ion-page {
  overflow: auto !important;
}

.dropdown-menu {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.171);
  // border: 1px solid #d6d6d6;
  border-radius: 4px;
  min-width: 180px;
  right: 0;
  top: 30px;
  // background: #fff;
  z-index: 999;
  padding: 15px;
}

.ant-menu {
  border-width: 1px;
  padding: 0.5rem;
  white-space: nowrap;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border-radius: 0.375rem;
  z-index: 50;
}

.hidden {
  display: none;
}
#searchInput:hover::placeholder {
  color: transparent;
}
.custom-shadow:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.171);
}
.custom_shadow_list {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.171);
}
.cm_post_comment_wrapper {
  background: #f6f6f6;
  margin: -16px;
  padding: 10px;
  margin-top: 0;
  border-radius: 0 0 8px 8px;
}
.my_rounded_table {
  border-collapse: collapse;
  width: 100%;
}

.my_rounded_table tbody tr:last-child td:first-child {
  border-radius: 0px 0px 0px 5px !important;
}

.my_rounded_table tbody tr:last-child td:last-child {
  border-radius: 0px 0px 5px 0px !important;
}


.my_rounded_table thead tr:first-child th:first-child {
  border-radius: 5px 0px 0px 0px !important;
}

.my_rounded_table thead tr:first-child th:last-child {
  border-radius: 0px 5px 0px 0px !important;
}

.border-highlight {
  position: relative;
}

.border-highlight::after {
  content: '';
  position: absolute;
  bottom: 0%; /* Adjust to center vertically */
  right: 1%; /* Adjust to center horizontally */
  height: 40%; /* Set height to 80% of cell height */
  width: 98%; /* Set width to 80% of cell width */
  border-bottom: 0.5px solid #ececec; /* Adjust the color and style of the highlighted border */
  z-index: 1; /* Ensure the highlighted border is above the original border */
}

.dark .border-highlight::after {
  border-bottom: 0.5px solid #535353; /* Adjust as needed */
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.cm_dropdwon_click:hover .dropdown-menu {
  display: block;
}

.cm_presentation_icon svg {
  height: 104px;
  width: 100%;
}

.cm_dropdwon_click > span {
  position: relative;
}

.menu-trigger-2:after,
.menu-trigger-2 span,
.menu-trigger-2:before,
.menu-trigger:after,
.menu-trigger span,
.menu-trigger:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  border-radius: 4px;
  left: 0;
}

.menu-trigger-2:after,
.menu-trigger-2 span,
.menu-trigger-2:before {
  background: #fff;
}

.menu-trigger-2:before,
.menu-trigger:before {
  top: 3px;
}

.menu-trigger-2 span,
.menu-trigger span {
  top: 13px;
}

.menu-trigger-2:after,
.menu-trigger:after {
  top: 23px;
}

.big_assignment_icon svg {
  width: 43px;
  height: 57px;
  margin: 0 auto;
}

.btn {
  padding: 8px 15px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 3px;
}

.btn_primary_ouline {
  border: 1px solid #00a05a;
  background: #fff;
  color: #00a05a;
}

.btn_primary {
  background: #00a05a;
  color: #fff;
  border: 1px solid #00a05a;
}

.cm_modal_wrapper {
  z-index: 9999;
  background: rgba(255, 255, 255, 0.5);
}

.cm_modal {
  // background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 50px #0000002b;
  border-radius: 17px;
}

.close_icon {
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
}

.close_icon:after,
.close_icon:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 15px;
  left: 0;
  left: 8px;
  top: 2px;
}

.close_icon:after {
  transform: rotate(45deg);
}

.close_icon:before {
  transform: rotate(-45deg);
}

.cm_placcholder {
  transform: translateY(0px);
  transition: all 0.3s;
}

// TODO: get rid of cm_form_control
input.cm_form_control:focus + .cm_placcholder,
input.cm_form_control.ng-dirty + .cm_placcholder,
input.cm_form_control:not(:placeholder-shown) + .cm_placcholder {
  transform: translateY(-11px);
  font-size: 12px;
}

.cm_steerip {
  background: linear-gradient(-180deg, #f4f4f4 4.43%, #ffffff 49.56%);
}

.cm_steerip_r {
  background: #fff;
  background: linear-gradient(0, #f4f4f4 4.43%, #ffffff 49.56%);
}

.owl-carousel .owl-item img {
  width: auto !important;
}

.cm_carousel_wrapper {
  max-width: 720px;
  margin: 0 auto;
  padding: 20px;
}
.rotate-270 {
  transform: rotate(270deg);
}

.cm_carousel_slide {
  max-width: 650px;
  min-width: 650px;
  width: 100%;
}

.cm_carousel_wrapper .owl-item {
  padding: 20px;
}

.cm_carousel_wrapper .owl-nav.ng-star-inserted {
  position: absolute;
  width: calc(100% + 25px);
  top: 42%;
  display: flex;
  justify-content: space-between;
  left: -8px;
}

.cm_carousel_wrapper .owl-nav > div {
  background: #00a05a !important;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.160784);
  width: 45px;
  height: 45px;
  font-size: 0 !important;
  border-radius: 100% !important;
  opacity: 1 !important;
  position: relative;
  cursor: pointer;
}

.cm_carousel_wrapper .owl-nav > div.disabled {
  background: #ffffff !important;
}

.cm_carousel_wrapper .owl-nav > div:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  right: 18px;
  top: 16px;
  transform: rotate(-135deg);
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.cm_carousel_wrapper .owl-nav > div.owl-prev:after {
  transform: rotate(45deg);
  right: 14px;
}

.cm_carousel_wrapper .owl-nav > div.disabled:after {
  border-left: 2px solid #8d8d8d;
  border-bottom: 2px solid #8d8d8d;
}

.home_menu a {
  position: relative;
}

.cm_carousel_wrapper {
  max-width: 720px;
  margin: 0 auto;
  padding: 20px;
}

.cm_carousel_slide {
  max-width: 650px;
  min-width: 650px;
  width: 100%;
}
.auth_bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.loading_btn {
  position: relative;
}

.loading_btn:after {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  top: 0;
  right: 15px;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

.cm_toggler {
  display: inline-block;
  position: relative;
  width: 44px;
  height: 21px;
  margin-right: 10px;
}

.cm_toggler > span {
  border-radius: 20px;
  position: absolute;
  width: 100%;
  height: 100%;
}

.cm_toggler_wrapper {
  display: flex;
  align-items: center;
}

.cm_toggler input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  opacity: 0;
  cursor: pointer;
}

.cm_toggler > span:after {
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  background: #fff;
  border-radius: 100%;
  left: 2px;
  top: 2px;
  transition: all 0.3s;
  transform: translateX(0px);
}

.cm_toggler input:checked + span {
  background: #00a05a;
}

.cm_toggler input:checked + span::after {
  transform: translateX(22px);
}

.day_mode,
.night_mode {
  width: 28px;
  height: 28px;
  background: #e8e8e5;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cm_night_mode_btn {
  width: 150px;
  height: 35px;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px;
}

.day_mode.active,
.night_mode.active {
  background: #00a05a;

  svg path {
    fill: #fff;
  }
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

main .cm_carousel_wrapper .owl-item {
  padding: 20px;
}

.cm_carousel_wrapper .owl-nav.ng-star-inserted {
  position: absolute;
  width: calc(100% + 25px);
  top: 42%;
  display: flex;
  justify-content: space-between;
  left: -8px;
}

.cm_carousel_wrapper .owl-nav > div {
  background: #00a05a !important;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.160784);
  width: 45px;
  height: 45px;
  font-size: 0 !important;
  border-radius: 100% !important;
  opacity: 1 !important;
  position: relative;
  cursor: pointer;
}

.cm_carousel_wrapper .owl-nav > div.disabled {
  background: #ffffff !important;
}

.cm_carousel_wrapper .owl-nav > div:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  right: 18px;
  top: 16px;
  transform: rotate(-135deg);
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.cm_carousel_wrapper .owl-nav > div.owl-prev:after {
  transform: rotate(45deg);
  right: 14px;
}

.cm_carousel_wrapper .owl-nav > div.disabled:after {
  border-left: 2px solid #8d8d8d;
  border-bottom: 2px solid #8d8d8d;
}

.home_menu a {
  position: relative;
}

.menu-trigger-2:after,
.menu-trigger-2 span,
.menu-trigger-2:before {
  background: #000;
}

.blue_gradiant {
  background: rgb(0, 40, 70);
  background: linear-gradient(
    231deg,
    rgba(0, 40, 70, 1) 0%,
    rgba(0, 127, 158, 1) 100%
  );
}

.cm_text_border {
  color: #007f9e;
  text-shadow:
    -1px 1px 0 #f14e9b,
    1px 1px 0 #f14e9b,
    1px -1px 0 #f14e9b,
    -1px -1px 0 #f14e9b;
}

.cm_student_tab {
  position: relative;
  bottom: -1.5px;
}
//scroller
/* width */
.cm_scroller::-webkit-scrollbar {
  width: 6px;
}

/* Handle */
.cm_scroller::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 4px;
}

/* Handle on hover */
.cm_scroller::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.cm_min_h {
  min-height: 140px;
}

@media (max-width: 1023px) {
  .home_menu {
    position: fixed;
    width: 100%;
    z-index: 9999;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    transform: translateY(-100%);
  }

  .home_menu_open .home_menu {
    transform: translateY(0%);
  }

  .home_menu ul {
    display: block;
  }

  .home_menu ul li a {
    font-size: 40px;
    text-align: center !important;
    display: block;
    // color: #000;
  }

  .home_menu ul li {
    margin: 30px 0;
  }

  .close_icon_2 {
    position: absolute;
    right: 30px;
    top: 20px;
    width: 23px;
    height: 38px;
  }

  .close_icon_2:after,
  .close_icon_2:before {
    width: 3px;
    height: 35px;
  }

  body.home_menu_open {
    overflow: hidden !important;
  }
}

@media (max-width: 767px) {
  body.home_menu_open {
    overflow: hidden !important;
  }
}

@media (max-width: 767px) {
  main .cm_carousel_slide {
    min-width: 200px;
  }

  .cm_carousel_wrapper .owl-nav.ng-star-inserted {
    width: calc(100% + 15px);
  }
}


.cm_checbox {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid #DBDBDB;
  background:#f6f6f6;
  border-radius: 4px;
  min-width: 18px;
}

.cm_checbox input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 9;
}



span.cm_checbox span {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: none;
}

.cm_checbox input:checked + span {
  display: block;
}

span.cm_checbox span:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 2px;
  background: #00A05A;
  transform: rotate(130deg);
  left: 5px;
  top: 7px;
  border-radius: 4px;
}

span.cm_checbox span:before {
  content: "";
  position: absolute;
  width: 6px;
  height: 2px;
  background: #00A05A;
  transform: rotate(43deg);
  left: 2.5px;
  top: 9px;
  border-radius: 4px;
}


/* Dark mode styles */
// .dark .cm_checbox {
//   border-color: #616161; /* Adjust color for dark mode */
//   background: #424242; /* Adjust background color for dark mode */
// }

#remove_style {
  position: static !important;
  transform: none !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  height: 24px;
  width: 48px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: rgb(0 160 90);
}

input:focus + .slider {
  box-shadow: 0 0 1px rgb(0 160 90);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 48px;
}

.slider.round:before {
  border-radius: 50%;
}

// phone input style
.iti {
	display: block !important;
	margin-bottom: 20px;
}

.iti .dropdown-menu.country-dropdown {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-color: #e5e7eb;
	margin-top: -1px;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
}

.iti .iti__country-list {
	box-shadow: none;
	font-size: 14px;
	margin-left: 0;
	width: 298px;
	max-height: 170px;
}

.iti__flag-container.open + input {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
  border-radius: 4px;
}

.iti .search-container input {
	font-size: 16px;
	border-color: #e5e7eb;
	border-radius: 0;
	padding: 5px 10px;
  font-weight: 500;
}

.iti__arrow {
  margin-left: 7px !important;
  width: 6px !important;
  height: 6px !important;
  border-left: 1.5px solid #000 !important;
  border-right: 0 !important;
  border-top: 0 !important;
  border-bottom: 1.5px solid #000;
  transform: rotate(-45deg);
}


.iti__selected-flag {
  gap : 6px !important;
  border-right: 1px solid #e5e7eb;
  width: 65px;
  height: 70% !important;
}
.iti__flag-container {
  display: flex;
  align-items: center;
}

.iti .search-container input:focus {
	outline: none;
}

@media screen and (max-width: 479px) {
	.iti .iti__country-list {
		width: 88.3vw;
	}
}

ngx-intl-tel-input input {
	height: 44px;
	margin-bottom: 20px;
	padding: 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #e5e7eb;
	border-radius: 4px;
	font-size: 18px;
  width: 300px;
}

ngx-intl-tel-input input:focus {
	outline: none !important;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
	color: #e5e7eb;
}

ngx-intl-tel-input input:-ms-input-placeholder {
	color: #e5e7eb;
}

ngx-intl-tel-input input::-ms-input-placeholder {
	color: #e5e7eb;
}

ngx-intl-tel-input input::placeholder {
	color: #e5e7eb;
}

.iti--allow-dropdown input, .iti--allow-dropdown input[type=text], .iti--allow-dropdown input[type=tel], .iti--separate-dial-code input, .iti--separate-dial-code input[type=text], .iti--separate-dial-code input[type=tel] {
    padding-right: 6px;
    padding-left: 70px !important;
    margin-left: 0; 
}

input#country-search-box {
  padding-left: 10px !important;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom: 1px solid #e5e7eb
}

ngx-intl-tel-input input[disabled] {
	background-color: #e5e7eb;
}

.country-dropdown[_ngcontent-ng-c2982863745] {
  border: 1px solid #ccc;
  width: -moz-fit-content;
  padding: 0px !important;
  border-collapse: collapse;
}

.iti__divider {
  margin-left: 4px;
  border-bottom: 1px solid #e5e7eb !important;
}

// .dark ngx-intl-tel-input input {
//   border-color: #616161; /* Adjust color for dark mode */
//   background: rgb(33 33 33); /* Adjust background color for dark mode */
// }

// .dark .iti__arrow {
//   border-left: 1.5px solid white !important;
//   border-bottom: 1.5px solid white;
// }

// .dark .iti .iti__country-list {
//   color: white;
//   border-color: #616161 ; /* Adjust color for dark mode */
//   background: rgb(33 33 33); /* Adjust background color for dark mode */
// }

// .dark .iti__divider {
//   border-bottom: 1px solid #616161 !important;
// }

// .dark input#country-search-box {
//   border-bottom: 1px solid #616161
// }

// .dark .iti .dropdown-menu.country-dropdown {
//   border: 1px solid #616161;
// }


//scroller
/* width */
.iti__country-list::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Handle */
.iti__country-list::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 4px;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #00A05A;
  color: #00A05A;
  border-radius: 4px;
  font-weight: 700;
  width: 117px;
  min-width: 117px;
  height: 48px;
  display: inline-block;
  padding: 12px 21px;
  cursor: pointer;
}

.custom-file-upload-2 {
  border: 1px solid #00A05A;
  color: #00A05A;
  border-radius: 4px;
  font-weight: 700;
  width: 148px;
  height: 46px;
  display: inline-block;
  padding: 12px 21px;
  cursor: pointer;
}
/* Handle on hover */
.iti__country-list::-webkit-scrollbar-thumb:hover {
  background: #888;
}


input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #0263D1;
  color: #0263D1;
  border-radius: 4px;
  font-weight: 700;
  width: 117px;
  height: 48px;
  display: inline-block;
  padding: 12px 21px;
  cursor: pointer;
}

.disabled-button {
  background-color: #e0e0e0; /* 灰色背景 */
  color: #808080; /* 灰色文本 */
  cursor: not-allowed; /* 鼠标样式为禁用 */
}

.custom-select {
  border: 1px solid #e5e7eb;
}